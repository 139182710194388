import '../assets/styles/sidebarMenu.css';
import useAuth from '../hooks/useAuth';

import { Link } from 'react-router-dom';

const SidebarMenu = ({ isMenuOpen, onLogout, onClose }) => {
    const { auth } = useAuth();

    const handleMenuClick = (callback) => {
        if (callback) callback();
    };

    return (
        <div id="sidebarMenu" className={`div-sidebar-menu ${isMenuOpen ? "translatein" : ""}`}>  
            {auth?.access_token ? (
                <>
                    <Link to="/admin_dashboard" onClick={() => handleMenuClick(onClose)} className="disable-select">Admin Dashboard</Link>
                    <Link to="/home" onClick={() => handleMenuClick(onClose)} className="disable-select">Recipes</Link>
                    <Link to="/profile" onClick={() => handleMenuClick(onClose)} className="disable-select">Profile</Link>
                    <button onClick={() => handleMenuClick(() => { onLogout(); onClose(); })} className="disable-select">Logout</button>
                </>
            ) : (
                <>
                    <Link to="/login" onClick={() => handleMenuClick(onClose)} className="disable-select">Login</Link>
                    <Link to="/" onClick={() => handleMenuClick(onClose)} className="disable-select">Recipes</Link>
                </>
            )}
        </div>
    );
}

export default SidebarMenu;
