import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import useAuth from '../hooks/useAuth';

import '../assets/styles/navbar.css';

const Navbar = ({ isMenuOpen, onMenuToggle, onMenuClose}) => {
    const { auth } = useAuth();
    const checkboxRef = useRef();
    const [isFloating, setIsFloating] = useState(false);

    useEffect(() => {
        if (checkboxRef.current) {
            checkboxRef.current.checked = isMenuOpen;
        }
    }, [isMenuOpen]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 10) {
                setIsFloating(true);
            } else {
                setIsFloating(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div id="navbar" className={`div-navbar disable-select ${isFloating ? 'div-navbar-floating' : ''}`}>
            <div className="div-navbar-left">
                <input
                    ref={checkboxRef}
                    type="checkbox"
                    className="openSidebarMenu"
                    id="menuToggle"
                    onChange={() => {
                        onMenuToggle('menu');
                    }}
                />
                <label htmlFor="menuToggle" className="menu-toggle disable-select">
                    <div className="spinner diagonal part-1"></div>
                    <div className="spinner horizontal"></div>
                    <div className="spinner diagonal part-2"></div>
                </label>
            </div>
            <div className="div-navbar-center disable-select">
                <>
                    {auth?.access_token ? (
                        <Link to="/home" onClick={() => onMenuClose()}>
                            Let's Cook
                        </Link>
                    ) : (
                        <Link to="/" onClick={() => onMenuClose()}>
                            Let's Cook
                        </Link>
                    )}
                </>
            </div>
            <div className="div-navbar-right"></div>
        </div>
    );
}

export default Navbar;