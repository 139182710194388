export const email_regex = /^[\w.-]+@[\w.-]+.\w+$/;
export const guid_regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
export const password_regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#'])[A-Za-z\d@$!%*?&#']{8,50}$/;
export const username_regex = /^[a-zA-Z0-9_]{3,30}$/;
export const tenant_name_regex = /^[a-zA-Z0-9_]{3,25}$/;
export const recipe_name_regex = /^[a-zA-Z0-9_\s]{3,255}$/;
export const ingredient_name_regex = /^[a-zA-Z0-9_\s()\-.,]{2,100}$/;
export const ingredient_quantity_regex = /^(?:\d{1,4}|\d{1,4}\/\d|\d{1,4}\.\d{1,2}|\d{1,4}\s\d\/\d)$/;
export const script_regex = /<script.*?>.*?<\/script>/i;
export const html_regex = /<[^>]*>/i;

export const validate_description = (value) => {
    if (!value) {
        throw new Error("description cannot be empty");
    } else if (script_regex.test(value)) {
        throw new Error("description contains potential script tags");
    } else if (html_regex.test(value)) {
        throw new Error("description contains potential HTML tags");
    }
};