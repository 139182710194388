import React, { useState, useEffect, useRef } from 'react';

import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { recipe_name_regex, validate_description,  } from '../utils/regex';

const CreateRecipe = ({ categories, onCreate, onCancel, onAlert }) => {
    const axiosPrivate = useAxiosPrivate();
    const inputRef = useRef(null);
    const [isAlertTriggered, setIsAlertTriggered] = useState(false);
    const [newRecipe, setNewRecipe] = useState({
        name: '',
        description: '',
        category_id: ''
    });

    const handleSubmitForm = async (e) => {
        e.preventDefault();

        if (!recipe_name_regex.test(newRecipe.name)) {
            setIsAlertTriggered(true);
            onAlert('invalid recipe name format: must be 3-255 characters long and contain only alphanumeric characters, underscores, and spaces');
            return;
        }

        try {
            validate_description(newRecipe.description);
        } catch (err) {
            setIsAlertTriggered(true);
            onAlert(err.message);
            return;
        }

        try {
            console.log(newRecipe);
            const response = await axiosPrivate.post('/app/recipe', newRecipe);
            onCreate(response.data);
        } catch (err) {
            setIsAlertTriggered(true);
            onAlert(err.response?.data?.detail || 'error creating recipe');
        }
    };
    
    const handleInputChange = (e) => {
        if (isAlertTriggered) {
            setIsAlertTriggered(false);
            onAlert('');
        }
        
        setNewRecipe(prevRecipe => ({
            ...prevRecipe,
            [e.target.name]: e.target.value
        }));
    };

    useEffect(() => {
        inputRef.current.focus();
    }, []);

    return (
        <div className="create-form-container">
            <form onSubmit={handleSubmitForm} className="create-form">
                <label htmlFor="name">Name:</label>
                <input
                    id="name"
                    ref={inputRef}
                    name="name"
                    type="text"
                    value={newRecipe.name}
                    onChange={handleInputChange}
                    required
                />
                <label htmlFor="description">Description:</label>
                <textarea
                    id="description"
                    name="description"
                    value={newRecipe.description}
                    onChange={handleInputChange}
                    required
                />
                <select
                    id="category_id"
                    name="category_id"
                    value={newRecipe.category_id}
                    onChange={(e) => handleInputChange(e)}
                    required
                >
                    <option value="">-- Category --</option>
                    {categories.map((category) => (
                        <option key={category.id} value={category.id}>
                            {category.name}
                        </option>
                    ))}
                </select>
                <div className="button-group">
                    <button type="submit" className="save-button">Save</button>
                    <button 
                        type="button" 
                        className="cancel-button" 
                        onClick={() => {
                            onAlert('');
                            onCancel();
                        }}
                    >
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    );
};

export default CreateRecipe;
