import { useEffect, useState, useCallback, useRef } from 'react'

const DisplayRecipe = ({ recipe, ingredientUnits, onClose, isLoading }) => {
    const bannerImage = useRef(null);
    // console.log(ingredientUnits);

    const getUnitName = (unit_id) => {
        const unit = ingredientUnits.find(unit => unit.id === unit_id);
        return unit ? unit.name : onClose;
    };
    
    useEffect(() => {
        const imageUrl = `data:${recipe.banner_image_type};base64,${recipe.banner_image}`;
        
        if (bannerImage.current) {
            bannerImage.current.src = imageUrl;
            bannerImage.current.focus();
        }
    }, [recipe]);

    return (
        <>
            {isLoading ? (
                <div className={`recipe-detail ${isLoading && 'loading'}`}>&nbsp;</div>
            ) : (
                <>
                    <div className="div-display-close disable-select" onClick={onClose}>X</div>
                    <div className="recipe-detail">
                        <div className="recipe-detail-banner">
                            <img
                                ref={bannerImage}
                                alt={recipe.title}
                            />
                        </div>
                        <div className="recipe-detail-content enable-select">
                            <h1>{recipe.name}</h1>
                            <p>{recipe.description}</p>
                            <div className="recipe-detail-ingredients">
                                <h3>Ingredients</h3>
                                <ul className="ingredients-list">
                                    {recipe.ingredients.map((ingredient) => (
                                        <li key={ingredient.id}>
                                            {`${ingredient.quantity} ${getUnitName(ingredient.unit_id)} of ${ingredient.name}`}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="recipe-detail-instructions">
                                <h3>Instructions</h3>
                                <ul>
                                    {recipe.instructions.map((instruction) => (
                                        <li key={instruction.id}>{instruction.description}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default DisplayRecipe