import { useState, useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';

import Navbar from './Navbar';
import SidebarMenu from './SidebarMenu';
import useLogout from '../hooks/useLogout';

import '../assets/styles/layout.css';

const Layout = () => {
    const logout = useLogout();
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [promptMessage, setPromptMessage] = useState('Install the app for quicker access.');
    const [showInstallPrompt, setShowInstallPrompt] = useState(false);

    const handleLogout = async () => {
        await logout();
        navigate('/');
    };

    useEffect(() => {
        const handleBeforeInstallPrompt = (e) => {
            e.preventDefault();
            setDeferredPrompt(e);
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        const isAppInstalled = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;

        if (!isAppInstalled) {
            const timer = setTimeout(() => {
                setPromptMessage('Install the app for quicker access.')
                setShowInstallPrompt(true);
            }, 30000);

            return () => {
                window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
                clearTimeout(timer);
            };
        } else {
            return () => {
                window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
            };
        }
    }, []);

    const handleInstallClick = () => {
        if (deferredPrompt) {
            deferredPrompt.prompt();
            deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    // console.log('User accepted the A2HS prompt');
                } else {
                    // console.log('User dismissed the A2HS prompt');
                }
                setDeferredPrompt(null);
                setShowInstallPrompt(false);
            });
        } else {
            const isIos = /iphone|ipad|ipod/.test(navigator.userAgent.toLowerCase());
    
            if (isIos) {
                setPromptMessage('To install this app on IOS, open the Share menu and select "Add to Home Screen".');
            } else {
                setPromptMessage('It looks like you already have the app installed. Check your home screen.');
            }
        }
    };

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://static.cloudflareinsights.com/beacon.min.js';
        script.setAttribute('data-cf-beacon', '{"token": "f689a9fc682c46ca89525e81cdfd6dc4"}');
        script.async = true;
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, []);

    return (
        <main className="div-main">
            <Navbar isMenuOpen={isMenuOpen} onMenuToggle={() => setIsMenuOpen(!isMenuOpen)} onMenuClose={() => setIsMenuOpen(false)} />
            <SidebarMenu isMenuOpen={isMenuOpen} onLogout={handleLogout} onClose={() => setIsMenuOpen(false)} />
            <div id="mainBody" className="div-main-body"></div>
            <Outlet />
            {showInstallPrompt && (
                <div className="install-prompt">
                    <p>{promptMessage}</p>
                    <div className="button-group">
                        <button onClick={handleInstallClick}>Install</button>
                        <button onClick={() => setShowInstallPrompt(false)}>Dismiss</button>
                    </div>
                </div>
            )}
        </main>

    );
};

export default Layout;