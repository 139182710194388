import React, { useEffect, useState, useCallback } from 'react';

import AlertMessage from '../components/AlertMessage';
import CreateRecipe from '../components/CreateRecipe';
import EditCard from '../components/EditCard';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

import '../assets/styles/management.css';

const AdminDashboard = () => {
    const axiosPrivate = useAxiosPrivate();
    const [alertMsg, setAlertMsg] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [fetchedCategories, setFetchedCategories] = useState([]);
    const [fetchedRecipes, setFetchedRecipes] = useState([]);
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const fetchCategories = useCallback(() => {
        const controller = new AbortController();
        let isMounted = true;
        
        const getCategories = async () => {
            try {
                const response = await axiosPrivate.get('/app/recipe_helper/recipe_categories', {
                    signal: controller.signal,
                });

                if (isMounted) {
                    setFetchedCategories(response.data);
                };
            } catch (err) {
                setErrMsg('no categories found');
            }
        };

        getCategories();

        return () => {
            isMounted = false;
            controller.abort();
        };
    }, [axiosPrivate]);
    
    const fetchRecipes = useCallback(async () => {
        const controller = new AbortController();
        let isMounted = true;

        const getRecipes = async () => {
            try {
                const response = await axiosPrivate.get('/app/recipes/admin', {
                    signal: controller.signal,
                });

                if (isMounted) {
                    setFetchedRecipes(response.data);
                    setIsLoading(false);
                }
            } catch (err) {
                setIsLoading(false);
                if (err?.response?.status === 404) {
                    setErrMsg('no recipes found');
                } else {
                    setAlertMsg('error fetching recipes');
                }
            }
        };

        getRecipes();

        return () => {
            isMounted = false;
            controller.abort();
        };
    }, [axiosPrivate]);

    const handleAlert = (msg) => {
        if (msg) {
            setAlertMsg(msg);
        } else {
            if (alertMsg) {
                setAlertMsg('');
            }
        }
    };

    const handleClickCreate = () => {
        if (!fetchedCategories.length) {
            setAlertMsg('error fetching categories');
            return;
        } else {
            setErrMsg('');
            setIsFormVisible(true);
        }
    };

    const handleCreateCanceled = () => {
        setIsFormVisible(false);
    };

    const handleRecipeCreated = (newRecipe) => {
        setIsFormVisible(false);
        setFetchedRecipes([...fetchedRecipes, newRecipe]);
    };

    const handleRecipeDeleted = (id) => {
        setFetchedRecipes(fetchedRecipes.filter((recipe) => recipe.id !== id));
    };

    const handleRecipeUpdated = (updatedRecipe) => {
        setFetchedRecipes(fetchedRecipes.map((recipe) => recipe.id === updatedRecipe.id ? updatedRecipe : recipe));
    };
    
    useEffect(() => {
        fetchCategories();
        fetchRecipes();
    }, [fetchRecipes, fetchCategories]);

    return (
        <>
            <AlertMessage message={alertMsg} category="error" onClose={() => setAlertMsg('')}/>
            <section className="section-edit disable-select">
                <div className={`overlay ${isFormVisible ? "show" : ""}`}>
                    {fetchedCategories.length && isFormVisible ? (
                        <CreateRecipe 
                            categories={fetchedCategories}
                            onCreate={handleRecipeCreated} 
                            onCancel={handleCreateCanceled} 
                            onAlert={handleAlert}
                        />
                    ) : null}
                </div>
                <div className="edit-container">
                    <div className="edit-header">
                        <h1>Admin Dashboard</h1>
                        <button onClick={handleClickCreate} className="create-button disable-select">Create Recipe</button>
                    </div>
                    <div className="edit-list">
                        {fetchedCategories.length && fetchedRecipes.length ? (
                            <ul className="item-list">
                                {fetchedRecipes.map((recipe) => (
                                    <li key={recipe.id} className="list-item disable-select">
                                        <EditCard 
                                            key={recipe.id} 
                                            recipe={recipe} 
                                            categories={fetchedCategories} 
                                            onUpdate={handleRecipeUpdated} 
                                            onDelete={handleRecipeDeleted} 
                                            onAlert={handleAlert}/>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <ul className="item-list">
                                <li className={`list-item ${isLoading ? 'loading' : ''}`}>
                                    <div className="item-icons-container">
                                        <p>{errMsg}&nbsp;</p>
                                    </div>
                                </li>
                            </ul>
                        )}
                    </div>
                </div>
            </section>
        </>
    )
}

export default AdminDashboard