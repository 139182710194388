import { useState, useEffect, useCallback, useRef } from 'react';

import { FaChevronDown, FaChevronUp, FaPlus } from 'react-icons/fa';

import EditIngredient from './EditIngredient';
import EditInstruction from './EditInstruction';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { validate_description, recipe_name_regex } from '../utils/regex';

const EditRecipe = ({ recipe, categories, isEditing, onUpdate, onClose, onAlert }) => {
    const axiosPrivate = useAxiosPrivate();
    const bannerImage = useRef(null);
    const detailToggleBtn = useRef(null);
    const editRecipeBtn = useRef(null);
    const focusPoint = useRef(null);
    const ingredientToggleBtn = useRef(null);
    const instructionsToggleBtn = useRef(null);
    const inputRecipeName = useRef(null);
    const [editedRecipe, setEditedRecipe] = useState(null);
    const [fetchedIngredientUnits, setFetchedIngredientUnits] = useState([]);
    const [fetchedRecipeDetail, setFetchedRecipeDetail] = useState(null);
    const [isAlertTriggered, setIsAlertTriggered] = useState(false);
    const [isDetailsVisible, setIsDetailsVisible] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [isBannerImageVisible, setIsBannerImageVisible] = useState(false);
    const [isIngredientUnitsLoaded, setIsIngredientUnitsLoaded] = useState(false);
    const [isIngredientsVisible, setIsIngredientsVisible] = useState(false);
    const [isInstructionsVisible, setIsInstructionsVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isRecipeDetailEdited, setIsRecipeDetailEdited] = useState(false);
    const [isRecipeDetailLoaded, setIsRecipeDetailLoaded] = useState(false);
    const [showNewIngredient, setShowNewIngredient] = useState(false);
    const [showNewInstruction, setShowNewInstruction] = useState(false);

    const fetchIngredientUnits = useCallback(() => {
        const controller = new AbortController();
        let isMounted = true;
        
        const getIngredientUnits = async () => {
            try {
                const response = await axiosPrivate.get('/app/recipe_helper/ingredient_units', {
                    signal: controller.signal,
                });

                if (isMounted) {
                    setIsIngredientUnitsLoaded(true);
                    setFetchedIngredientUnits(response.data);
                };
            } catch (err) {
                setIsAlertTriggered(true);
                onAlert('error fetching ingredient units');
                onClose();
            }
        };

        getIngredientUnits();

        return () => {
            isMounted = false;
            controller.abort();
        };
    }, [axiosPrivate, onAlert, onClose]);
    
    const fetchRecipeDetails = useCallback(() => {
        const controller = new AbortController();
        let isMounted = true;

        const getRecipe = async () => {
            try {
                const response = await axiosPrivate.get(`/app/recipe/${recipe.id}`, {
                    signal: controller.signal,
                });

                if (isMounted) {
                    setIsLoading(false);
                    setIsRecipeDetailLoaded(true);
                    setFetchedRecipeDetail(response.data);
                    setEditedRecipe(response.data);
                    setIsEditMode(isEditing);
                };
            } catch (err) {
                setIsAlertTriggered(true);
                onAlert('error fetching recipe');
                onClose();
            }
        };

        getRecipe();

        return () => {
            isMounted = false;
            controller.abort();
        };
    }, [axiosPrivate, recipe.id, isEditing, onAlert, onClose]);

    const handleClickCancel = () => {
        setIsAlertTriggered(false);
        onAlert('');
        const { name, description, is_published } = fetchedRecipeDetail;
        setEditedRecipe({ ...editedRecipe, name, description, is_published });
        setIsRecipeDetailEdited(false);
        setIsEditMode(false);
    };

    const handleIngredientCreated = async (newIngredient) => {
        setEditedRecipe({ ...editedRecipe, ingredients: [...editedRecipe.ingredients, newIngredient] });
        setShowNewIngredient(false);
    };

    const handleIngredientDeleted = async (id) => {
        setEditedRecipe({ ...editedRecipe, ingredients: editedRecipe.ingredients.filter((ingredient) => ingredient.id !== id) });
    };

    const handleIngredientUpdated = (updatedIngredient) => {
        setEditedRecipe({ ...editedRecipe, ingredients: editedRecipe.ingredients.map((ingredient) => ingredient.id === updatedIngredient.id ? updatedIngredient : ingredient) });
    };

    const handleInstructionCreated = async (newInstruction) => {
        setEditedRecipe({ ...editedRecipe, instructions: [...editedRecipe.instructions, newInstruction] });
        setShowNewInstruction(false);
    };

    const handleInstructionDeleted = async (id) => {
        setEditedRecipe({ ...editedRecipe, instructions: editedRecipe.instructions.filter((instruction) => instruction.id !== id) });
    };

    const handleInstructionUpdated = (updatedInstruction) => {
        setEditedRecipe({ ...editedRecipe, instructions: editedRecipe.instructions.map((instruction) => instruction.id === updatedInstruction.id ? updatedInstruction : instruction) });
    };

    const handleInputChange = async (e) => {
        if (isAlertTriggered) {
            setIsAlertTriggered(false);
            onAlert('');
        }

        let { id, value } = e.target;

        if (value === 'on') {
            value = e.target.checked;
        } else if (value === 'off') {
            value = e.target.checked;
        }

        if (value !== fetchedRecipeDetail[id]) {
            setIsRecipeDetailEdited(true);
        } else {
            setIsRecipeDetailEdited(false);
        }

        setEditedRecipe({ ...editedRecipe, [id]: value });
    };

    const handleSubmitFormBannerImage = async (e) => {
        setIsLoading(true);
        
        const fileInput = e.target.files[0];
        if (!fileInput) {
            setIsLoading(false);
            return;
        }

        const formData = new FormData();
        formData.append('file', fileInput);
        formData.append('image_purpose', 'banner');

        try {
            const response = await axiosPrivate.post(`/app/recipe/${editedRecipe.id}/upload_image`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 200) {
                onAlert('');
                setFetchedRecipeDetail(response.data);
                setIsLoading(false);
                // setIsBannerImageVisible(false);
            }
        } catch (err) {
            setIsLoading(false);
            onAlert(err.response?.data?.detail || err.message);
        }
    };
    
    const handleSubmitFormDetail = async (e) => {
        e.preventDefault();

        if (!recipe_name_regex.test(editedRecipe.name)) {
            setIsAlertTriggered(true);
            onAlert('invalid recipe name format: must be 3-255 characters long and contain only alphanumeric characters, underscores, and spaces');
            return;
        }

        try {
            validate_description(editedRecipe.description);
        } catch (err) {
            setIsAlertTriggered(true);
            onAlert(err.message);
            return;
        }

        try {
            const { id, created, created_by, ingredients, instructions, banner_image, banner_image_type, ...editedRecipeBody } = editedRecipe;
            const response = await axiosPrivate.put(`/app/recipe/${editedRecipe.id}`, editedRecipeBody);
            if (response.status === 200) {
                onAlert('');
                setFetchedRecipeDetail(response.data);
                setIsRecipeDetailEdited(false);
                onUpdate(response.data);
            }
        } catch (err) {
            onAlert(err.response?.data?.detail || err.message);
        }
    };

    const toggleBannerImageVisibility = () => {
        setIsBannerImageVisible(!isBannerImageVisible);
    };

    const toggleDetailsVisibility = () => {
        setIsDetailsVisible(!isDetailsVisible);
    };

    const toggleIngredientsVisibility = () => {
        const ingredientsForms = document.querySelectorAll('.ingredients-form');
        if (ingredientsForms.length > 0) {
            ingredientsForms[ingredientsForms.length - 1].style.marginBottom = '20px';
        }
        setIsIngredientsVisible(!isIngredientsVisible);
    };

    const toggleInstructionsVisibility = () => {
        setIsInstructionsVisible(!isInstructionsVisible);
    };

    useEffect(() => {
        if (!isRecipeDetailLoaded) {
            fetchIngredientUnits();
            fetchRecipeDetails();
        }
    }, [fetchIngredientUnits, fetchRecipeDetails, isRecipeDetailLoaded]);

    useEffect(() => {
        if (isIngredientUnitsLoaded && isRecipeDetailLoaded) {
            const imageUrl = `data:${fetchedRecipeDetail.banner_image_type};base64,${fetchedRecipeDetail.banner_image}`;
            if (isBannerImageVisible && bannerImage.current) {
                bannerImage.current.src = imageUrl;
            }
        }
    }, [isIngredientUnitsLoaded, isRecipeDetailLoaded, isBannerImageVisible, fetchedRecipeDetail]);

    return (
        <>
            <div ref={focusPoint} tabIndex="-1" className="item-container enable-select">            
                <h1 className="disable-select">{recipe.name}</h1>
                <div className={`section-header ${isLoading ? 'loading' : ''}`}>
                    <label htmlFor="ingredients" className="disable-select">Details:</label>
                    <button 
                        ref={detailToggleBtn} 
                        type="button" 
                        className="toggle-button" 
                        disabled={!isIngredientUnitsLoaded || !isRecipeDetailLoaded} 
                        onClick={toggleDetailsVisibility}
                    >
                        {isDetailsVisible ? <FaChevronUp /> : <FaChevronDown />}
                    </button>
                </div>
                {isDetailsVisible && (
                    <>
                        <div className="item-container-info">
                            <div className="info-field">
                                <label className="disable-select">ID:</label>
                                <div className={`info-value`}>{fetchedRecipeDetail?.id || <>&nbsp;</>}</div>
                            </div>
                        </div>
                        <form id="formDetail" className={`edit-form ${isEditMode && 'editing'} `} onSubmit={handleSubmitFormDetail}>
                            <label htmlFor="name" className="disable-select">Name:</label>
                            <input
                                className={`{!isEditMode ? 'disable-select' : ''}`}
                                ref={inputRecipeName}
                                id="name"
                                type="text"
                                value={editedRecipe?.name || ''}
                                onChange={(e) => handleInputChange(e)}
                                required
                                readOnly={!isEditMode}
                            />
                            <label htmlFor="description" className="disable-select">Description:</label>
                            <textarea
                                className={`{!isEditMode ? 'disable-select' : ''}`}
                                id="description"
                                name="description"
                                value={editedRecipe?.description}
                                onChange={handleInputChange}
                                readOnly={!isEditMode}
                                required
                            />
                            <select
                                id="category_id"
                                name="category_id"
                                value={editedRecipe?.category_id}
                                onChange={(e) => handleInputChange(e)}
                                required
                            >
                                <option value="">-- Category --</option>
                                {categories.map((category) => (
                                    <option key={category.id} value={category.id}>
                                        {category.name}
                                    </option>
                                ))}
                            </select>
                            <div className="checkbox-row disable-select">
                                <label htmlFor="is_published">Published:</label>
                                <input
                                    id="is_published"
                                    type="checkbox"
                                    checked={editedRecipe?.is_published}
                                    onChange={(e) => handleInputChange(e)}
                                    disabled={!isEditMode}
                                />
                            </div>
                            {isEditMode && isRecipeDetailEdited && (
                                <div className="button-group">
                                    <button type="submit" className="save-button disable-select">Save</button>
                                    <button type="button" className="cancel-button disable-select" onClick={handleClickCancel}>Cancel</button>
                                </div>
                            )}
                        </form>
                    </>
                )}
                <div className={`section-header ${isLoading ? 'loading' : ''}`}>
                    <label htmlFor="ingredients" className="disable-select">Banner Image:</label>
                    <button 
                        ref={ingredientToggleBtn} 
                        type="button" 
                        className="toggle-button" 
                        disabled={!isIngredientUnitsLoaded || !isRecipeDetailLoaded} 
                        onClick={toggleBannerImageVisibility}
                    >
                        {isBannerImageVisible ? <FaChevronUp /> : <FaChevronDown />}
                    </button>
                </div>
                {isBannerImageVisible && (
                    <>
                        {fetchedRecipeDetail?.banner_image ? (
                            <img ref={bannerImage} alt="Banner" className={`disable-select ${isEditMode && 'editing'}`} />
                        ) : (
                            <div className="section-body disable-select">
                                <div className="info-value ">No banner image</div>
                            </div>
                        )}
                        {isEditMode && (
                            <form id="formBannerImage" className="edit-form">
                                <label htmlFor="bannerImage" className="custom-file-upload disable-select">Update Banner Image</label>
                                <input 
                                    type="file" 
                                    id="bannerImage" 
                                    name="bannerImage" 
                                    accept=".jpg, .jpeg, .png"
                                    disabled={!isEditMode || isLoading} 
                                    onChange={handleSubmitFormBannerImage}
                                />
                            </form>
                        )}
                    </>
                )}
                <div className={`section-header ${isLoading ? 'loading' : ''}`}>
                    <label htmlFor="ingredients" className="disable-select">Ingredients:</label>
                    <button 
                        ref={ingredientToggleBtn} 
                        type="button" 
                        className="toggle-button" 
                        disabled={!isIngredientUnitsLoaded || !isRecipeDetailLoaded} 
                        onClick={toggleIngredientsVisibility}
                    >
                        {isIngredientsVisible ? <FaChevronUp /> : <FaChevronDown />}
                    </button>
                </div>
                {isIngredientsVisible && (
                    <div className="section-body disable-select">
                        {editedRecipe?.ingredients.length ? (
                            editedRecipe?.ingredients.map((ingredient) => (
                                <EditIngredient
                                    key={ingredient.id} 
                                    recipeId={editedRecipe.id}
                                    ingredient={ingredient} 
                                    ingredientUnits={fetchedIngredientUnits} 
                                    isEditMode={isEditMode} 
                                    onDelete={handleIngredientDeleted} 
                                    onCreate={handleIngredientCreated}
                                    onCreateCancel={() => setShowNewIngredient(false)}
                                    onUpdate={handleIngredientUpdated}
                                    onAlert={onAlert}
                                />
                            ))
                        ) : (
                            <div className="info-value">No ingredients</div>
                        )}
                        {showNewIngredient && (
                            <EditIngredient
                                key="newIngredient"
                                recipeId={editedRecipe.id}
                                ingredient=''
                                ingredientUnits={fetchedIngredientUnits}
                                isEditMode={isEditMode}
                                onDelete={handleIngredientDeleted}
                                onCreate={handleIngredientCreated}
                                onCreateCancel={() => setShowNewIngredient(false)}
                                onUpdate={handleIngredientUpdated}
                                onAlert={onAlert}
                            />
                        )}
                        {isEditMode && !showNewIngredient && (
                            <div className="ingredients-button-group">
                                <button type="button" className="add-button disable-select" onClick={() => {onAlert(''); setShowNewIngredient(true);}}>
                                    <FaPlus />
                                </button>
                            </div>
                        )}
                    </div>
                )}
                <div className={`section-header ${isLoading ? 'loading' : ''}`}>
                    <label htmlFor="instructions" className="disable-select">Instructions:</label>
                    <button 
                        ref={instructionsToggleBtn}
                        type="button" 
                        className="toggle-button" 
                        disabled={!isIngredientUnitsLoaded || !isRecipeDetailLoaded} 
                        onClick={toggleInstructionsVisibility}
                    >
                        {isInstructionsVisible ? <FaChevronUp /> : <FaChevronDown />}
                    </button>
                </div>
                {isInstructionsVisible && (
                    <div className="section-body disable-select">
                        {editedRecipe?.instructions.length ? (
                            editedRecipe?.instructions.map((instruction) => (
                                <EditInstruction
                                    key={instruction.id} 
                                    recipeId={editedRecipe.id}
                                    instruction={instruction} 
                                    isEditMode={isEditMode} 
                                    onDelete={handleInstructionDeleted}
                                    onCreate={handleInstructionCreated}
                                    onCreateCancel={() => setShowNewIngredient(false)}
                                    onUpdate={handleInstructionUpdated}
                                    onAlert={onAlert}
                                />
                            ))
                        ) : (
                            <div className="info-value">No instructions</div>
                        )}
                        {showNewInstruction && (
                            <EditInstruction
                                key="newInstruction" 
                                recipeId={editedRecipe.id}
                                instruction='' 
                                isEditMode={isEditMode} 
                                onDelete={handleInstructionDeleted}
                                onCreate={handleInstructionCreated}
                                onCreateCancel={() => setShowNewInstruction(false)}
                                onUpdate={handleInstructionUpdated}
                                onAlert={onAlert}
                            />
                        )}
                        {isEditMode && !showNewInstruction && (
                            <div className="ingredients-button-group">
                                <button type="button" className="add-button disable-select" onClick={() => {onAlert(''); setShowNewInstruction(true);}}>
                                    <FaPlus />
                                </button>
                            </div>
                        )}
                    </div>
                )}
                <div className="button-group">
                    {!isEditMode && !isLoading && (
                        <button 
                            ref={editRecipeBtn} 
                            type="button" 
                            className="edit-button disable-select" 
                            disabled={!isIngredientUnitsLoaded || !isRecipeDetailLoaded} 
                            onClick={() => {
                                onAlert(''); 
                                setIsEditMode(true);
                            }}
                        >Edit</button>
                    )}

                    <button type="button" className="cancel-button disable-select" onClick={() => {onAlert(''); onClose();}}>Close</button>
                </div>
            </div>
        </>
    );
};

export default EditRecipe;