import { useEffect, useState, useCallback, useRef } from 'react';

import DisplayCard from '../components/DisplayCard';
import DisplayRecipe from '../components/DisplayRecipe';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

import '../assets/styles/home.css';

const Index = () => {
    const axiosPrivate = useAxiosPrivate();
    const scrollableDivRef = useRef(null);
    const [clickedCategories, setClickedCategories] = useState([]);
    const [displayedRecipeDetail, setDisplayedRecipeDetail] = useState([]);
    const [errMsg, setErrMsg] = useState('');
    const [fetchedCategories, setFetchedCategories] = useState([]);
    const [fetchedIngredientUnits, setFetchedIngredientUnits] = useState([]);
    const [fetchedRecipes, setFetchedRecipes] = useState([]);
    const [fetchedRecipeDetails, setFetchedRecipeDetails] = useState([]);
    const [isCategoriesLoaded, setIsCategoriesLoaded] = useState(false);
    const [isIngredientUnitsLoaded, setIsIngredientUnitsLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [showRecipeDetail, setShowRecipeDetail] = useState(false);
    
    const fetchCategories = useCallback(() => {
        const controller = new AbortController();
        let isMounted = true;
        
        const getCategories = async () => {
            try {
                const response = await axiosPrivate.get('/app/recipe_helper/recipe_categories', {
                    signal: controller.signal,
                });

                if (isMounted) {
                    setIsCategoriesLoaded(true);
                    setFetchedCategories(response.data);
                };
            } catch (err) {
                setErrMsg('There is a problem with the application. Please try again later.');
            } finally {
                setIsLoading(false);
            }
        };

        getCategories();

        return () => {
            isMounted = false;
            controller.abort();
        };
    }, [axiosPrivate]);
    
    const fetchIngredientUnits = useCallback(() => {
        const controller = new AbortController();
        let isMounted = true;
        
        const getIngredientUnits = async () => {
            try {
                const response = await axiosPrivate.get('/app/recipe_helper/ingredient_units', {
                    signal: controller.signal,
                });

                if (isMounted) {
                    setIsIngredientUnitsLoaded(true);
                    setFetchedIngredientUnits(response.data);
                };
            } catch (err) {
                setErrMsg('no recipes found');
            }
        };

        getIngredientUnits();

        return () => {
            isMounted = false;
            controller.abort();
        };
    }, [axiosPrivate]);
    
    const fetchRecipes = useCallback(async (categoryId) => {
        const controller = new AbortController();
        let isMounted = true;
    
        const getRecipes = async () => {
            try {
                const response = await axiosPrivate.get(`/app/recipes/${categoryId}`, {
                    signal: controller.signal,
                });
    
                if (isMounted) {
                    setFetchedRecipes(prevRecipes => [...prevRecipes, ...response.data]);
                    setIsLoading(false);
                }
            } catch (err) {
                setIsLoading(false);
                if (err?.response?.status !== 404) {
                    setIsCategoriesLoaded(false);
                    setErrMsg('There was an error displaying the recipes. Please try again later.');
                }
            }
        };
    
        getRecipes();
    
        return () => {
            isMounted = false;
            controller.abort();
        };
    }, [axiosPrivate]);
    
    const fetchRecipeDetail = useCallback(async (recipeId) => {
        const controller = new AbortController();
        let isMounted = true;

        const getRecipeDetail = async () => {
            try {
                const response = await axiosPrivate.get(`/app/recipe/${recipeId}`, {
                    signal: controller.signal,
                });

                if (isMounted) {
                    setFetchedRecipeDetails(prevRecipeDetails => [...prevRecipeDetails, response.data]);
                    setDisplayedRecipeDetail(response.data);
                    setIsLoading(false);
                }
            } catch (err) {
                setErrMsg('There was an error displaying the recipe. Please try again later.');
                setIsCategoriesLoaded(false);
            }
        };

        getRecipeDetail();

        return () => {
            isMounted = false;
            controller.abort();
        };
    }, [axiosPrivate]);
    
    const handleRecipeClick = (recipeId) => {
        setIsLoading(true);
        setShowRecipeDetail(true);
        document.getElementById('categoryMenu')?.classList.add('category-container-hide');
        if (!fetchedRecipeDetails.some(recipe => recipe.id === recipeId)) {
            fetchRecipeDetail(recipeId);
        } else {
            setDisplayedRecipeDetail(fetchedRecipeDetails.find(recipe => recipe.id === recipeId));
            setIsLoading(false);
        }
    };

    const handleRecipeClose = () => {
        setShowRecipeDetail(false);
        document.getElementById('categoryMenu')?.classList.remove('category-container-hide');
    };

    const handleCategoryClick = (categoryId) => {
        setIsLoading(true);
        setSelectedCategory(categoryId);
        setClickedCategories(prevClickedCategories => {
            if (!prevClickedCategories.includes(categoryId)) {
                fetchRecipes(categoryId);
                return [...prevClickedCategories, categoryId];
            } else {
                setIsLoading(false);
            }
            return prevClickedCategories;
        });
    }
    
    useEffect(() => {
        if (!isCategoriesLoaded && !isIngredientUnitsLoaded) {
            fetchCategories();
            fetchIngredientUnits();
        }
    }, [fetchCategories, isCategoriesLoaded, fetchIngredientUnits, isIngredientUnitsLoaded]);

    useEffect(() => {
        const handleScroll = () => {
            if (scrollableDivRef.current.scrollTop > 10) {
                document.getElementById('categoryMenu')?.classList.add('category-container-floating');
            } else {
                document.getElementById('categoryMenu')?.classList.remove('category-container-floating');
            }
        };

        document.getElementById('categoryMenu')?.classList.remove('category-container-floating');
        const scrollableDiv = scrollableDivRef.current;

        if (scrollableDiv) {
            scrollableDiv.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (scrollableDiv) {
                scrollableDiv.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);
    
    return (
        <>
            <div id="categoryMenu" className={`category-container `}>
                {isCategoriesLoaded ? (
                    fetchedCategories.map((category) => (
                        <button 
                            key={category.id} 
                            onClick={() => {handleCategoryClick(category.id)}}
                            className={selectedCategory === category.id ? 'selected' : ''}
                        >
                            {category.name}
                        </button>
                    ))
                ) : (
                    <button className="category-button-placeholder" disabled>&nbsp;</button>
                )}
            </div>
            <section ref={scrollableDivRef} className={`section-display disable-select ${isLoading ? 'loading' : ''}`}>
                <div className={`sub-overlay ${showRecipeDetail ? "show" : ""}`}>
                    {showRecipeDetail && displayedRecipeDetail && (
                        <DisplayRecipe
                            recipe={displayedRecipeDetail} 
                            ingredientUnits={fetchedIngredientUnits} 
                            onClose={handleRecipeClose} 
                            isLoading={isLoading}
                        />
                    )}
                </div>
                {isCategoriesLoaded && isIngredientUnitsLoaded && !selectedCategory ? (
                    <div className="welcome-container">
                        <p>Welcome to your ultimate recipe hub! Whether you're looking to recreate family favorites or discover new dishes, we've got you covered.</p>
                        <p>Browse through the categories to find a variety of recipes, from quick weeknight meals to indulgent desserts, and get inspired for any occasion.</p>
                        <p>Start exploring and unlock flavorful recipes that will make cooking at home easier and more enjoyable. What will you cook today?</p>
                    </div>
                ) : isCategoriesLoaded && isIngredientUnitsLoaded && selectedCategory ? (
                    <div className="card-container">
                        {fetchedRecipes.filter(recipe => recipe.category_id === selectedCategory).length ? (
                            fetchedRecipes.filter(recipe => recipe.category_id === selectedCategory).map((recipe) => (
                                <DisplayCard 
                                    key={recipe.id}
                                    recipe={recipe} 
                                    onClick={() => handleRecipeClick(recipe.id)}
                                />
                            ))
                        ) : (
                            !isLoading && (
                                <div className="no-recipes-container">
                                    <p>There are no recipes for this category.</p>
                                </div>
                            )
                        )}
                    </div>
                ) : (
                    <div className="welcome-container"> 
                        <p>{errMsg}</p>
                    </div>
                )}
            </section>
        </>
    )
}

export default Index
